import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/organisms/seo';
import RichText from '../components/molecules/richtext';
import { useClientSide } from '../hooks/useClientSide';

const Cookies = ({ data }) => {
  const isClient = useClientSide();
  const { title, _rawContent } = data.cookies.nodes[0];

  function setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
  }

  function deleteCookie(name) {
    return isClient && setCookie(name, '', -1);
  }

  return (
    <div className="spacing-top-big">
      <SEO siteTitle={title} />
      <div className="legal grid">
        <p className="title">{title}</p>
        <RichText blocks={_rawContent} />
        <button
          style={{
            width: 'auto',
            position: 'relative',
            display: 'inline-block',
            textAlign: 'left',
            textDecoration: 'underline',
          }}
          type="button"
          onClick={() => deleteCookie('gatsby-gdpr-google-analytics')}
        >
          Withdraw cookie consent here.
        </button>
      </div>
    </div>
  );
};

export const query = graphql`
  query Cookies {
    cookies: allSanityCookies {
      nodes {
        title
        _rawContent
      }
    }
  }
`;

Cookies.propTypes = {
  data: PropTypes.object,
};

export default Cookies;
